import de from '../../translation/de_DE'

export default {
  // i18nConfiguration
  i18n: {
    locales: [
      {
        code: 'de',
        iso: 'de-DE',
      },
    ],
    defaultLocale: 'de',
    vueI18n: {
      fallbackLocale: 'de',
      messages: {
        de,
      },
    },
    pages: {
      'categories/business': {
        de: '/business',
      },
      'categories/get-inspired': {
        de: '/inspiration',
      },
      'categories/how-to': {
        de: '/so-wirds-gemacht',
      },
      'categories/news': {
        de: '/news',
      },
      'categories/printstories': {
        de: '/printstories',
      },
      'editorial-staff/index': {
        de: '/redaktion',
      },
      'editorial-staff/_author': {
        de: '/redaktion/:author?',
      },
    },
  },
  /* 
    Categories Configuration
    @order: [] è l'array delle chiavi delle categorie e rappresenta l'ordine con cui verranno visualizzate le categorie dove richieste
    list{
        @key è la chiave della categoria. Per convenzione chiamata come il folder dentro page.
        {
          @slug è lo slug della categoria inserito in categorie su Wordpress
          @view è la view di nuxt e corrisponde al name utilizzato nei nuxt-link
        }
    }
  */
  pages: {
    // leggere README.md
    list: [
      {
        key: 'index', // Home per comodità la trattiamo come una categoria
        wpUri: '/',
      },
      {
        key: 'business',
        wpUri: '/business/',
      },
      {
        key: 'get-inspired',
        wpUri: '/inspiration/',
      },
      {
        key: 'how-to',
        wpUri: '/so-wirds-gemacht/',
      },
      {
        key: 'news',
        wpUri: '/news/',
      },
      {
        key: 'printstories',
        wpUri: '/printstories/',
      },
      {
        key: 'editorial-staff',
        wpUri: '/redaktion/',
      },
    ],
  },
  categories: {
    // leggere README.md
    list: [
      {
        key: 'business',
        wpSlug: 'business',
        view: 'categories-business',
        wpCategoryId: 4096,
      },
      {
        key: 'get-inspired',
        wpSlug: 'lasciatiispirare',
        view: 'categories-get-inspired',
        wpCategoryId: 69,
      },
      {
        key: 'how-to',
        wpSlug: 'comesifa',
        view: 'categories-how-to',
        wpCategoryId: 67,
      },
      {
        key: 'news',
        wpSlug: 'lenovita',
        view: 'categories-news',
        wpCategoryId: 70,
      },
      {
        key: 'printstories',
        wpSlug: 'printstories',
        view: 'categories-printstories',
        wpCategoryId: 6980,
      },
      {
        key: 'highlights',
        wpSlug: 'highlights',
        view: null,
        wpCategoryId: 6979,
      },
      {
        key: 'business-growth',
        wpSlug: 'businessgrowth',
        view: null,
        wpCategoryId: 6978,
      },
    ],
  },
  pixartWebsite: 'https://www.pixartprinting.de/',
  navigation: {
    primary: {
      order: ['business', 'printstories', 'how-to', 'get-inspired', 'news'],
    },
    // Categorie che vengono filtrate da eventuali elenchi (es. la lista delle categorie a cui appartiene un post)
    exclude: ['highlights', 'business-growth'],
  },
  social: [
    {
      key: 'facebook',
      url: 'https://www.facebook.com/pixartprinting.de/',
    },
    {
      key: 'instagram',
      url: 'https://www.instagram.com/pixartprinting/',
    },
    {
      key: 'linkedin',
      url: 'https://www.linkedin.com/company/pixartprinting/',
    },
  ],
  terms: [
    {
      key: 'privacy-policy',
      url: 'https://www.pixartprinting.de/datenschutz/',
    },
  ],
  shopLinks: [
    {
      label: 'Online Druckerei',
      url: 'https://www.pixartprinting.de/',
    },
    {
      label: 'Visitenkarten',
      url: 'https://www.pixartprinting.de/visitenkarten/',
    },
    {
      label: 'Flugblätter und Flyer',
      url: 'https://www.pixartprinting.de/digitaldruck-offsetdruck-online/flyer-drucken/',
    },
    {
      label: 'Personalisierte Etiketten',
      url: 'https://www.pixartprinting.de/aufkleber-und-etiketten-drucken/',
    },
    {
      label: 'Aufkleber',
      url: 'https://www.pixartprinting.de/aufkleber-und-etiketten-drucken/sticker/',
    },
    {
      label: 'Verpackungen',
      url: 'https://www.pixartprinting.de/verpackungen-drucken/',
    },
    {
      label: 'Pappschachteln',
      url: 'https://www.pixartprinting.de/verpackungen-drucken/standardverpackungen/',
    },
    {
      label: 'Tragetaschen',
      url: 'https://www.pixartprinting.de/personalisierte-tragetaschen/',
    },
    {
      label: 'Zeitschriften, Bücher, Kataloge',
      url: 'https://www.pixartprinting.de/broschueren-drucken/',
    },
    {
      label: 'Leinwandbilder',
      url: 'https://www.pixartprinting.de/fotoprodukte/druck-auf-leinwand/',
    },
    {
      label: 'Werbegeschenke',
      url: 'https://www.pixartprinting.de/personalisierte-werbegeschenke/',
    },
    {
      label: 'Kalender und Planer',
      url: 'https://www.pixartprinting.de/kalender-online-drucken/',
    },
  ],
  nuxtConfiguration: {
    head: {
      /* meta: [
        {
          name: 'google-adsense-account',
          content: 'ca-pub-6774238467243801',
        },
      ],
      script: [
        {
          src: 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js',
          async: true,
          'data-ad-client': 'ca-pub-6774238467243801',
          callback: () => {
            const ads = document.querySelectorAll('.adsbygoogle')
            // eslint-disable-next-line
            ads.forEach(() => (adsbygoogle = window?.adsbygoogle || []).push({}))
          },
        },
      ], */
    },
  },
}
